/*
 * Action types { ... }
 * ******************** */

// Employer
export const GET_DASHBOARD_EMPLOYER = "GET_DASHBOARD_EMPLOYER";
export const GET_PROFIL_EMPLOYER = "GET_PROFIL_EMPLOYER";
export const POST_PROFIL_EMPLOYER = "POST_PROFIL_EMPLOYER";
export const PUT_PROFIL_EMPLOYER = "PUT_PROFIL_EMPLOYER";
export const GET_PROFIL_USER = "GET_PROFIL_USER";
export const PUT_PROFIL_USER = "PUT_PROFIL_USER";
export const PUT_PROFIL_USER_PW = "PUT_PROFIL_USER_PW";

export const GET_API_SIRET = "GET_API_SIRET";
export const GET_OFFER = "GET_OFFER";
export const POST_OFFER = "POST_OFFER";
export const DELETE_OFFER = "DELETE_OFFER";
export const PUT_ACTION_CANDIDATE = "PUT_ACTION_CANDIDATE";
export const POST_MESSAGE_CANDIDATE = "POST_MESSAGE_CANDIDATE";

// Messages
export const GET_MESSAGES = "GET_MESSAGES";
export const POST_MESSAGES = "POST_MESSAGES";
export const DELETE_MESSAGES = "DELETE_MESSAGES";

// Candidate
export const GET_PROFIL_CANDIDATE = "GET_PROFIL_CANDIDATE";
export const POST_PROFIL_CANDIDATE = "POST_PROFIL_CANDIDATE";
export const PUT_PROFIL_CANDIDATE = "POST_PROFIL_CANDIDATE";
export const DELETE_PROFIL_CANDIDATE = "DELETE_PROFIL_CANDIDATE";
// OffreCandidat
export const GET_OFFRE_CANDIDATE = "GET_OFFRE_CANDIDATE";
export const DELETE_OFFRE_CANDIDATE = "DELETE_PROFIL_CANDIDATE";

// Admin
export const GET_LIST_USERS = "GET_LIST_USERS";
export const GET_USER = "GET_USER";
export const PUT_USER = "PUT_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_LIST_JOBS = "GET_LIST_JOBS";
export const GET_JOB = "GET_JOB";
export const PUT_JOB = "PUT_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const GET_LIST_MESSAGES = "GET_LIST_MESSAGES";
export const GET_MESSAGE = "GET_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

// Auth
export const LOGIN = 'LOGIN';
export const CHECKTOKEN = 'CHECKTOKEN';
export const REGISTER = 'REGISTER';
export const CHANGEMDP = 'CHANGEMDP';

// Contact Us
export const POST_CONTACT_US = 'POST_CONTACT_US';

// Offres visiteur
export const GET_OFFRE_VISITEUR = 'GET_OFFRE_VISITEUR';
export const GET_OFFRE_VISITEURID = 'GET_OFFRE_VISITEURID';

// Offre favorite
export const GET_OFFRE_FAVORITE_ID = 'GET_OFFRE_FAVORITE_ID';
export const POST_OFFRE_FAVORITE = 'POST_OFFRE_FAVORITE';
export const DELETE_OFFRE_FAVORITE = 'DELETE_OFFRE_FAVORITE';

// Visiteur Data
export const GET_VISITEUR_DATA_ENTREPRISE_AVATAR = 'GET_VISITEUR_DATA_ENTREPRISE_AVATAR';
