export const style = {
  h6: { color: "white" },
  ".MuiDrawer-root > .MuiPaper-root": {
    overflow: "visible",
    backgroundColor: "#fff",
  },
  TextField: {
    width: "100%",
  },
};
