import React from 'react'
import Box from "@mui/material/Box";

export default function Title() {
    return (
        <Box>
            SKEW
        </Box>
    )
}
